.navbar {
  position: fixed;
  z-index: 2;
  right: 0;
  left: 0;
  height: 3rem;
  background: #3f3f3f;
  color: white;
  font-family: "Inter", sans-serif;

  display: flex;
  align-items: center;

  padding-left: 5rem;
  padding-right: 5rem;
}

.navbar .name {
  margin-right: auto;
  font-weight: 800;
  font-size: 1.5rem;
}

.navbar .item {
  padding-left: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  .navbar {
    height: 3rem;
    background: none;
    padding-left: 1rem;
    position: absolute;
  }

  .navbar .item {
    display: none;
  }

  .navbar .name {
    font-size: 1rem;
  }
}
