.despre {
  width: 100%;
  background-color: #e0e0e0;

  box-sizing: border-box;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.despre .container {
  display: flex;
  flex-direction: row;
}

.despre .text {
  flex-grow: 1;
}

.despre picture {
  height: 400px;
}

.despre img {
  height: 400px;
  border-radius: 12px;
}

.despre h2 {
  margin-bottom: 1.8rem;
  font-size: 1.6rem;
}

.despre li {
  margin-bottom: 1.4rem;
  font-size: 1.2rem;
}

.despre label {
  padding-bottom: 1rem;
  color: #444444;
}

@media screen and (max-width: 480px) {
  .despre {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .despre .container {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  .despre img {
    align-self: center;
  }
}
